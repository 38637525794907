<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <div class="d-flex justify-content-between align-items-center mt-4 mb-4">
        <a class="page-title">Bundles</a>
        <div ngbDropdown class="d-inline-block">
          <button [disabled]="loading || activatingBundle" type="button" class="btn team-dropdown" id="dropdownBasic1" ngbDropdownToggle>
              {{ selectedBillingAccount ? selectedBillingAccount.name : 'Billing Account' }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button
              *ngFor="let billingAccount of displayedBillingAccounts"
              [disabled]="loading || activatingBundle"
              (click)="selectBillingAccount(billingAccount)" 
              ngbDropdownItem>
              {{ billingAccount.name }}
            </button>
          </div>
        </div>
      </div>

      <table class="table table-hover" *ngIf="!loading else loadingTemplate">
        <thead>
          <tr>
            <th scope="col">Index</th>
            <th scope="col">Billing Account Name</th>
            <th scope="col">Purchase date</th>
            <th scope="col">Expiration Date</th>
            <th scope="col" class="text-center">Number of Searches Remaining</th>
            <th scope="col" class="text-center">Status</th>
            <th scope="col" class="text-center">Active</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let bundle of displayedBundles; index as i">
            <th scope="row">{{ i + 1 }}</th>
              <td scope="row">{{ bundle.ownerName }}</td>
              <td scope="row">{{ bundle.purchaseDate | date: 'MMM d, y, h:mm:ss a' }}</td>
              <td scope="row">{{ bundle.expirationDate | date: 'MMM d, y, h:mm:ss a'}}</td>
              <td scope="row" class="text-center">{{ bundle.creditsCount }}</td>
              <td scope="row" class="text-center">{{ bundle.status }}</td>
              <td scope="row" class="text-center">
                <input
                  [ngModel]="bundle.active"
                  [disabled]="!canBundleBeActivated(bundle)"
                  (change)="activateBundle(bundle)"
                  class="switch mr-2"
                  type="checkbox">
              </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
  
<ng-template #loadingTemplate>
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</ng-template>
