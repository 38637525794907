import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './no-billing-account-modal.component.html',
  styleUrls: ['./no-billing-account-modal.component.scss'],
})
export class NoBillingAccountModal {

  constructor(
    private modalRef: NgbActiveModal,
  ) {}

  public close() {
    this.modalRef.close();
  }
}
