<div class="blue-bg">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-7 col-lg-7 menu-left">
        <img src="assets/white-logo4.png" id="logo" width="270" (click)="logoClick()" />
        <div class="header-links" *ngIf="isAuthenticated()">
          <div class="link-wrapper">
            <a class="header-link" [class.active]="!isLinkActive('billing')" (click)="goToHome()">Search</a>
            <span class="arrow" *ngIf="!isLinkActive('billing')"></span>
          </div>
          <span class="small-line">|</span>
          <div class="link-wrapper">
            <a class="header-link" [class.active]="isLinkActive('billing')" (click)="goToBilling()">Billing</a>
          <span class="arrow" *ngIf="isLinkActive('billing')"></span>
          </div>
        </div>
      </div>
    
      <div class="col-12 col-md-5 col-lg-5 menu-right">
          <a href="javascript:void(0)" class="servingNewChildLink" (click)="childServedClick()"
            *ngIf="isAuthenticated()" [autoClose]="false" #servingChildToolTip="ngbTooltip" tooltipClass="black-tooltip"
            ngbTooltip="Click this link each time you serve a new child using People Search." placement="bottom">Serving A
            New Child?</a>
    
          <span class="small-line mx-3" *ngIf="isAuthenticated()">|</span>

          <div class="round-menu">
            <div ngbDropdown class="d-inline-block">
              <button type="button" class="no-style-btn" id="dropdownBasic1" ngbDropdownToggle>
               <img src="../../assets/node-blue 1.png">
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" *ngIf="!isAuthenticated()">
                <button ngbDropdownItem  href="javascript:void(0);" (click)="login()">Log In</button>
                <button ngbDropdownItem href="javascript:void(0)"(click)="register()">Sign Up</button>
              </div>
              <div ngbDropdownMenu aria-labelledby="dropdownBasic1" *ngIf="isAuthenticated()">
                <button ngbDropdownItem href="javascript:void(0)"(click)="logout()">Log Out</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<ng-template #socialWorkerCheckModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Do you work with foster kids?</h4>
  </div>
  <div class="modal-body">
    <p>People Search is for social workers and those that work directly with foster kids. If you do not work with foster
      kids, we'd love to tell you more about what we do. </p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="yesIAmASocialWorkerClick()">Yes, I work with
      foster kids</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="noIAmNotASocialWorkerClick()">Nope,
      that's not me</button>
  </div>
</ng-template>

<ng-template #notASocialWorkerModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Thanks for stopping by!</h4>
  </div>
  <div class="modal-body">
    <p>Please visit our website to learn more about our work. </p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="visitConnectOurKids()">Visit
      ConnectOurKids.org</button>
  </div>
</ng-template>

<ng-template #watchVideoModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Two minutes for better results</h4>
  </div>
  <div class="modal-body">
    <p>Before you start, we'd like you to watch a two minute video so that you know everything about People Search. </p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="openVideoClick()">Show me the video</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="dontWatchVideoClick()">Skip the
      video</button>
  </div>
</ng-template>

<ng-template #videoModal let-c="close" let-d="dismiss">
  <div class="modal-body">
    <iframe class="videoIframe" width="100%"
      src="https://www.youtube.com/embed/36OwzSMHHk8?rel=0&autoplay=1&modestbranding=1&controls=1&wmode=transparent"
      frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen></iframe>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="postWatchVideoSignUp()">Take me to sign
      up</button>
  </div>
</ng-template>

<ng-template #childServedModal let-c="close" let-d="dismiss">

  <div class="modal-header">
    <h4 class="modal-title">Thank-you!</h4>
  </div>
  <div class="modal-body">
    <p>You are a superhero to foster kids, and your work here is important. Thanks for helping us inform our funders of
      your efforts.</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c(ChildServedModalResult.EXIT)">Back to
      search</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal"
      (click)="c(ChildServedModalResult.CANCEL)">Oops, didn't mean to click that</button>
  </div>

</ng-template>
