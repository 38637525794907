<div id="openBillingAccountsModal">
    <div class="modal-header">
        <h4 class="modal-title">Please select your billing account</h4>
    </div>
    <div class="modal-body">
        <p>Select a billing account with available searches to continue your work.</p>
    </div>
    <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="close()">Close</button>
    </div>
</div>
