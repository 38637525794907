import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, of } from "rxjs";
import { AuthService } from "../auth.service";
import { HttpClient } from "@angular/common/http";
import { catchError, switchMap, tap } from "rxjs/operators";
import { CreateUserInput, Roles, Team, User } from "@connect-our-kids/connect-our-kids-lib/generated/graphql";

export interface TeamWithMetadata extends Team {
    isPersonalTeam: boolean;
    isUserManager: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class TeamUserService {

    private readonly TEAM_USERS_API = "/api/team/users"
    private readonly REMOVE_USER_FROM_TEAM_API = "/api/team/user"
    private readonly ADD_USER_TO_TEAM_API = "/api/team/user"

    private userTeams = new ReplaySubject<TeamWithMetadata[]>(1);
    private teamUsers = new ReplaySubject<User[]>(1);

    private loadedTeamUsers = false;
    private selectedTeamId: number;

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
    ) {
        this.loadUserTeams();
    }

    public getUserTeams(): Observable<TeamWithMetadata[]> {
        return this.userTeams;
    }

    public getUsersOfTeam(teamId: number, forceReload = false): Observable<User[]> {
        if (!forceReload && this.loadedTeamUsers && teamId === this.selectedTeamId) {
            return this.teamUsers;
        }

        return this.loadTeamUsers(teamId)
            .pipe(
                tap(() => this.loadedTeamUsers = true)
            );
    }

    public removeUser(userId: number): Observable<User> {
        return this.httpClient.delete<User>(this.REMOVE_USER_FROM_TEAM_API, {
            headers: { 'authorization': 'Bearer ' + this.authService.accessToken },
            params: {
                teamId: this.selectedTeamId,
                userId: userId
            }
        })
            .pipe(
                tap(() => this.getUsersOfTeam(this.selectedTeamId, true).subscribe()),
                switchMap((user) => of(user))
            );
    }

    public createUser(creatUserInput: CreateUserInput): Observable<User> {
        return this.httpClient.post<User>(this.ADD_USER_TO_TEAM_API, {
            firstName: creatUserInput.firstName,
            lastName: creatUserInput.lastName,
            emailAddress: creatUserInput.emailAddress,
            teamId: this.selectedTeamId,
            role: creatUserInput.role
        }, {
            headers: { 'authorization': 'Bearer ' + this.authService.accessToken },
        })
            .pipe(
                tap(() => this.getUsersOfTeam(this.selectedTeamId, true).subscribe()),
                switchMap((user) => of(user))
            );
    }

    private loadUserTeams(): void {
        this.authService.getUser().subscribe((user) => {
            const userTeamsWithMetadata = user.userTeams.map((userTeam) => {
                return {
                    ...userTeam.team,
                    isUserManager: Roles.MANAGER === userTeam.role
                } as TeamWithMetadata;
            });

            this.userTeams.next(userTeamsWithMetadata);
        });
    }

    private loadTeamUsers(teamId: number): Observable<User[]> {
        return this.httpClient.get<User[]>(this.TEAM_USERS_API, {
            headers: { 'authorization': 'Bearer ' + this.authService.accessToken },
            params: { teamId }
        }).pipe(
            catchError(() => of([])),
            tap(users => {
                this.teamUsers.next(users);
                this.selectedTeamId = teamId;
            }),
            switchMap(() => this.teamUsers)
        );
    }
}
