<div class="container">
  <p class="mt-5">
    Based on the information you supplied or that we discovered about your use of the Family Connections tool, we have
    <span class="text-bold">disabled</span> your account.
  </p>
  <p>
    If you would like your account re-enabled, please reply to this message or email
    <a href="mailto:support@connectourkids.org" class="text-bold">support&#64;connectourkids.org</a>.
  </p>
  <p>
    Please review our terms of service agreement to ensure that your use of the tool is in
    compliance with our rules.
  </p>
</div>
