<div class="container-fluid p-0">
  <app-header></app-header>
  <div class="container">
    <h1>See you later!</h1>
    <p>Your account is now logged-out in this browser. You can safely close the browser window now.</p>
  
    <app-footer></app-footer>
  </div>

</div>
