<div class="container">
    <app-header></app-header>
  <div class="row">
    <div class="col-12">
      <h1>Welcome!</h1>
      <p>We're glad you joined us! Please check your email for a verification request.</p>

      <h4>No Verification Email?</h4>
      <p>Be sure to check your spam folder.</p>
    </div>
  </div>

    <app-footer></app-footer>

</div>
