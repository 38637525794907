<div class="container">
    <app-header></app-header>
  <div class="row">
    <div class="col-12">
      <h1>Are you a social worker?</h1>
      <p>To gain access to this free tool, we need to verify that you are a social worker or advocate working with foster kids.
      Click the button below to send an email requesting access to this free tool.</p>
      <a href="mailto:support@connectourkids.org?subject=People%20Search%20Access&body=Connect%20Our%20Kids%20Support%20Team%2C%20%0A%0AI%20work%20with%20foster%20kids%2C%20and%20would%20like%20free%20access%20to%20the%20People%20Search%20tool.%0A"><button type="button" class="btn btn-primary">Send Access Request Email</button></a>
    </div>
  </div>

    <app-footer></app-footer>

</div>
