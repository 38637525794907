<app-header></app-header>

<div class="sub-header">
    <div class="container">
       <div class="d-flex justify-content-between align-items-center">
            <ul ngbNav #nav="ngbNav" [(activeId)]="selectedTabIndex" class="nav-tabs">
                <li [ngbNavItem]="page.route" *ngFor="let page of PAGES">
                    <button ngbNavLink (click)="changeTab(page.route)" routerLinkActive="active-route" [routerLink]="page.route" [ngClass]="{ 'active-tab': selectedTabIndex === page.route }">{{ page.title }}</button>
                </li>
            </ul>
            <div ngbDropdown class="d-inline-block">
                <label class="team-label">Team: </label>
                <button [disabled]="loadingTeams" type="button" class="btn team-dropdown" id="teamDropdown" ngbDropdownToggle>
                    {{ selectedTeam ? selectedTeam.name : 'Team' }}
                </button>
                <div ngbDropdownMenu aria-labelledby="teamDropdown">
                    <button ngbDropdownItem *ngFor="let team of teams" (click)="selectTeam(team)"> {{ team.name }} </button>
                </div>
          </div>
       </div>
    </div>
</div>

<div [ngbNavOutlet]="nav" class="mt-5"></div>

<div class="container">
    <router-outlet></router-outlet>
</div>
