import { Component, OnDestroy, OnInit } from '@angular/core';
import { TeamService } from '../team.service';
import { TeamUserService, TeamWithMetadata } from '../services/team-user.service';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BillingAccountWithMetadata, BillingService } from '../billing.service';

@Component({
  selector: 'billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss'],
})
export class BillingComponent implements OnInit, OnDestroy {

  private readonly ON_DESTROY = new Subject();

  public readonly PAGES = [
    { title: 'Billing accounts', route: 'billing-accounts' },
    { title: 'Bundles', route: 'bundles' },
    { title: 'Credits Sharing', route: 'team' }
  ];

  public selectedTabIndex = this.PAGES[0].route;

  public teams: TeamWithMetadata[] = [];
  public selectedTeam: TeamWithMetadata;

  public loadingTeams = false;

  constructor(
    private teamService: TeamService,
    private teamUserService: TeamUserService,
    private billingService: BillingService,
  ) {
  }

  public ngOnInit(): void {
    this.loadTeamsAndSelectDefaultTeam();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();  
    this.ON_DESTROY.complete();  
  }

  public changeTab(tabIndex: string): void {
    this.selectedTabIndex = tabIndex;
  }

  public selectTeam(team: TeamWithMetadata): void {
    this.selectedTeam = team;
    this.teamService.setSelectedTeam(team);
  }

  private loadTeamsAndSelectDefaultTeam(): void {
    this.loadingTeams = true;
    this.billingService.getSelectedBillingAccount()
      .pipe(first())
      .subscribe((billingAccount) => {
        this.teamUserService.getUserTeams()
          .pipe(takeUntil(this.ON_DESTROY))
          .subscribe((teams) => {
            const personalTeam = {
              name: 'Personal Team',
              isPersonalTeam: true,
            } as TeamWithMetadata;

            this.teams = [personalTeam, ...teams];
            this.selectedTeamFromSelectedBillingAccount(billingAccount);
            this.loadingTeams = false;
          });
      });
  }

  private selectedTeamFromSelectedBillingAccount(selectedBillingAccount: BillingAccountWithMetadata): void {
    const selectedTeamId = selectedBillingAccount.teamId;
    const selectedTeam = this.teams.find((team) => team.id == selectedTeamId);

    this.selectTeam(selectedTeam);
  }
}
