<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <div class="d-flex justify-content-between align-items-center mt-4 mb-4">
          <a class="page-title">Billing Accounts</a>
          <div class="btn-wrapper">
            <button [disabled]="loadingBillingAccounts || !selectedTeam?.isUserManager || selectedTeam?.isPersonalTeam" type="button" class="btn btn-primary main-user-button" (click)="openCreateBillingAccountModal()">Create billing account</button>
          </div>
        </div>
      <table class="table table-hover" *ngIf="!loadingBillingAccounts else loadingTemplate">
        <thead>
          <tr>
            <th scope="col">Name of the account</th>
            <th scope="col">Email address</th>
            <th scope="col">Description</th>
            <th scope="col">Credits</th>
            <th scope="col" class="text-center">Active</th>
            <th scope="col" class="text-right">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let billingAccount of displayedBillingAccounts">
            <th scope="row">{{ billingAccount.name }}</th>
              <td scope="row">{{ billingAccount.email }}</td>
              <td scope="row">{{ billingAccount.description }}</td>
              <td scope="row">{{ billingAccount.creditsCount }}</td>
              <td scope="row" class="text-center">
                <label class="checkbox-container mb-0">
                  <input type="radio" name="radio"
                    [disabled]="savingBillingAccount"
                    [checked]="billingAccount.id === activeBillingAccount?.id"
                    (click)="setActiveBillingAccount(billingAccount)">
                  <span class="checkmark"></span>
                </label>
                
              </td>
              <td class="text-right">
                <button [disabled]="!selectedTeam.isUserManager" type="button" class="btn user-icon">
                  <img class="credits-img" src="../../assets/credit-card.png" (click)="openAddCreditsToBillingAccountModal(billingAccount)">
                </button>
                <button [disabled]="!selectedTeam.isUserManager || CreditType.PERSONAL === billingAccount.type" type="button" class="btn user-icon" (click)="openEditBillingAccountModal(billingAccount)">
                  <img src="../../assets/edit.png">
                </button>
                <button [disabled]="!selectedTeam.isUserManager || CreditType.PERSONAL === billingAccount.type" type="button" class="btn user-icon" (click)="openDeleteBillingAccountConfirmationModal(billingAccount)">
                  <img src="../../assets/trash.png">
                </button>
              </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ng-template #billingAccountSettingsModal id="create-billing-modal" class="modal">
  <div class="wrapper">
    <div class="modal-wrapper">
      <form [formGroup]="billingAccountForm">
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title">Create Billing Account</h4>
        </div>
        <div class="modal-body py-3">
          <div class="pb-4">
            <label class="mb-1">Name of the Account</label>
            <input type="text" class="form-control" formControlName="name" placeholder="Name of the Account" />
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.name.touched && billingAccountForm.controls.name.errors">Please
              provide a name for the account!</span>
          </div>

          <div class="pb-4">
            <label class="mb-1">Email Address</label>
            <input type="email" class="form-control" formControlName="emailAddress" placeholder="Email Address" />
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.emailAddress.touched && billingAccountForm.controls.emailAddress.errors">{{emailError}}</span>
          </div>

          <div class="pb-3">
            <label class="mb-1">Description</label>
            <textarea type="text" class="form-control" formControlName="description"
              placeholder="Description"></textarea>
            <span class="error-txt"
              *ngIf="billingAccountForm.controls.description.touched && billingAccountForm.controls.description.errors">Please
              provide a description for the account!</span>
          </div>
          <input class="switch mr-2" type="checkbox">
          Is default
          <button type="button" class="tooltip-btn" ngbTooltip="Set as default account if you want all of your team to use this as primary account.">
            <img src="../../assets/info.png">
          </button>
        </div>
        <div class="modal-footer justify-content-between">
          <button [disabled]="loadingBillingAccounts" type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
          <button type="button" class="btn btn-primary" (click)="saveBillingAccount()"
            [disabled]="billingAccountFormHasError || savingBillingAccount">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-template>

<ng-template #deleteBillingAccountConfirmationModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirm Delete</h4>
  </div>
  <div class="modal-body py-4">
        <p class="mb-0">Are you sure you want to delete this Billing Account?</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button [disabled]="deletingBillingAccount" type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
    <button [disabled]="deletingBillingAccount" type="button" class="btn btn-danger" (click)="deleteBillingAccount()">Delete</button>
  </div>
</ng-template>

<ng-template #addCreditsModal id="demo-modal" class="modal">
  <div class="wrapper">
    <div class="modal-wrapper">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Choose your bundle</h4>
      </div>
      <div class="modal-body py-4">
        <p class="mb-3">Select one of the bundles below and then click continue</p>
        <div class="row">
          <div class="col-md-6" (click)="selectBundleToPurchase(BillingProductPackageType.PEOPLE_SEARCH_1_CREDIT)" [class.selected]="selectedBundle === BillingProductPackageType.PEOPLE_SEARCH_1_CREDIT">
            <div class="card">
              <div class="card-content">
                <p class="mb-0 gray-txt">Individual searches</p>
                <h4>$2.99/search</h4>
              </div>
            </div>
          </div>
          <div class="col-md-6" (click)="selectBundleToPurchase(BillingProductPackageType.PEOPLE_SEARCH_100_CREDITS)" [class.selected]="selectedBundle === BillingProductPackageType.PEOPLE_SEARCH_100_CREDITS">
            <div class="card">
              <div class="card-content">
                <p class="mb-0 gray-txt">Bundles of 100 searches</p>
                <h4>$99/bundle</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
        <button type="button" class="btn btn-primary main-user-button" [disabled]="!selectedBundle || rediredcting"
          (click)="openCheckoutSession(true)">Continue</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loadingTemplate>
  <div class="loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>
</ng-template>
