import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BillingAccountWithMetadata, BillingService } from '../billing.service';
import { AuthService } from '../auth.service';
import { first, take, takeUntil } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BillingProductPackageType, CreateBillingAccount, CreditType, Team, UpdateBillingAccount } from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { TeamUserService, TeamWithMetadata } from '../services/team-user.service';
import { Subject, merge } from 'rxjs';
import { TeamService } from '../team.service';

@Component({
  selector: 'billing-accounts',
  templateUrl: './billing-accounts.component.html',
  styleUrls: ['./billing-accounts.component.scss'],
})
export class BillingAccountsComponent implements OnInit, OnDestroy {

  public readonly BillingProductPackageType = BillingProductPackageType;
  public readonly CreditType = CreditType;

  private readonly ON_DESTROY = new Subject<void>();
  private readonly ON_BILLING_ACCOUNTS_CHANGE = new Subject<void>();
  
  @ViewChild('billingAccountSettingsModal', { static: true })
  public billingAccountSettingsModal: ElementRef;
  
  @ViewChild('deleteBillingAccountConfirmationModal', { static: true })
  public deleteBillingAccountConfirmationModal: ElementRef;

  @ViewChild('addCreditsModal', { static: true })
  public addCreditsModal: ElementRef;

  public loadingBillingAccounts = false;
  public displayedBillingAccounts: BillingAccountWithMetadata[] = [];
  public savingBillingAccount: boolean = false;
  public deletingBillingAccount: boolean = false;

  public billingAccountForm = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    emailAddress: new UntypedFormControl('', [Validators.required, Validators.email]),
    isDefault: new UntypedFormControl(false, []),
  });

  public selectedTeam: TeamWithMetadata;
  public activeBillingAccount: BillingAccountWithMetadata;

  public selectedBundle: BillingProductPackageType;
  public redirectingToStripe = false;

  private billingAccounts: BillingAccountWithMetadata[] = [];
  private selectedBillingAccount: BillingAccountWithMetadata;
    
  constructor(
    private modal: NgbModal,
    private billingService: BillingService,
    private teamService: TeamService,
  ) { }

  public ngOnInit(): void {
    this.loadBillingAccounts();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();

    this.ON_DESTROY.complete();
    this.ON_BILLING_ACCOUNTS_CHANGE.complete();
  }

  public openCreateBillingAccountModal() {
    this.modal.open(this.billingAccountSettingsModal, {
      size: 'md',
      centered: true,
      beforeDismiss: () => {
        return true;
      }
    });
  }

  public openAddCreditsToBillingAccountModal(billingAccount: BillingAccountWithMetadata) {
    this.selectedBillingAccount = billingAccount;
    this.modal.open(this.addCreditsModal, {
      size: 'md',
      centered: true,
      beforeDismiss: () => {
        return true;
      }
    });
  }

  public openEditBillingAccountModal(billingAccount: BillingAccountWithMetadata) {
    this.selectedBillingAccount = billingAccount;
    this.billingAccountForm.setValue({
      name: billingAccount.name,
      description: billingAccount.description,
      emailAddress: billingAccount.emailAddress,
      isDefault: billingAccount.isDefault
    });

    this.modal.open(this.billingAccountSettingsModal, {
      size: 'md',
      centered: true,
      beforeDismiss: () => {
        return true;
      }
    });
  }
  
  public openDeleteBillingAccountConfirmationModal(billingAccount: BillingAccountWithMetadata) {
    this.selectedBillingAccount = billingAccount;
    this.modal.open(this.deleteBillingAccountConfirmationModal, {
			size: 'md',
			centered: true,
		});
  }

  public saveBillingAccount(): void {
    if (this.savingBillingAccount) {
      return;
    }

    this.savingBillingAccount = true;

    if (!this.billingAccountForm.valid) {
      this.billingAccountForm.markAllAsTouched();
      this.billingAccountForm.markAsDirty();
      return;
    }

    if (this.isEditingAccount) {
      this.editBillingAccount();
    } else {
      this.createBillingAccount();
    }
  }

  public createBillingAccount(): void {
    const controls = this.billingAccountForm.controls;
    const billingAccountCreationPayload: CreateBillingAccount = {
      name: controls.name.value,
      emailAddress: controls.emailAddress.value,
      description: controls.description.value,
      isDefault: controls.isDefault.value,
    }

    this.billingService.createBillingAccount(this.selectedTeam.id, billingAccountCreationPayload)
      .subscribe(
        () => {
          this.close();
          this.savingBillingAccount = false;
        },
        () => {
          this.savingBillingAccount = false;
        }
      );
  }

  public editBillingAccount(): void {
    const controls = this.billingAccountForm.controls;
    const billingAccountUpdatePayload: UpdateBillingAccount = {
      id: this.selectedBillingAccount.id,
      name: controls.name.value,
      emailAddress: controls.emailAddress.value,
      description: controls.description.value,
      isDefault: controls.isDefault.value,
    }

    this.billingService.updateBillingAccount(this.selectedTeam.id, billingAccountUpdatePayload)
      .subscribe(
        () => {
          this.close();
          this.savingBillingAccount = false;
        },
        () => {
          this.savingBillingAccount = false;
        }
      );
  }

  public setActiveBillingAccount(billingAccount: BillingAccountWithMetadata): void {
    this.billingService.setSelectedBillingAccount(billingAccount);
  }

  public deleteBillingAccount(): void {
    this.deletingBillingAccount = true;
    this.billingService.deleteBillingAccount(this.selectedTeam.id, this.selectedBillingAccount.id)
      .subscribe(
        () => {
          this.close();
          this.deletingBillingAccount = false;
        },
        () => {
          this.deletingBillingAccount = false;
        }
      );
  }

  public close(): void {
    this.selectedBillingAccount = null;
    this.modal.dismissAll();
  }

  public selectBundleToPurchase(bundleType: BillingProductPackageType) {
    if (this.redirectingToStripe) {
      return;
    }

    this.selectedBundle = bundleType;
  }

  public openCheckoutSession(): void {
    this.redirectingToStripe = true;
    this.billingService.billingCheckoutSession(this.selectedBillingAccount, this.selectedBundle).subscribe((session) => {
      window.location.href = session.url;
      this.close();
    });
  }

  public get emailError(): string {
    return this.billingAccountForm.controls.emailAddress.hasError('email') ? "Please enter a valid email" : 'Please enter an email for the account!';
  }

  public get isEditingAccount(): boolean {
    return this.selectedBillingAccount != null;
  }

  public get billingAccountFormHasError(): boolean {
    const allFieldsTouched =
      this.billingAccountForm.controls.emailAddress.touched &&
      this.billingAccountForm.controls.name.touched &&
      this.billingAccountForm.controls.description.touched;

    return allFieldsTouched && this.billingAccountForm.invalid;
  }
  
  private loadBillingAccounts(): void {
    this.loadingBillingAccounts = true;
    this.billingService.getBillingAccounts()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe(accounts => {
        this.billingAccounts = accounts;
        this.ON_BILLING_ACCOUNTS_CHANGE.next();

        this.filterBillingAccountsOnSelectedTeamChange();

        this.billingService.getSelectedBillingAccount()
          .pipe(first())
          .subscribe((account) => {
            this.activeBillingAccount = account;
            this.loadingBillingAccounts = false;
          });
      });
  }

  private filterBillingAccountsOnSelectedTeamChange(): void {
    const componentDestructionOrBillingAccountsChange = merge(this.ON_DESTROY, this.ON_BILLING_ACCOUNTS_CHANGE);
    this.teamService.getSelectedTeam()
      .pipe(takeUntil(componentDestructionOrBillingAccountsChange))
      .subscribe((team) => this.selectTeam(team));
  }

  private selectTeam(team: TeamWithMetadata): void {
    this.selectedTeam = team;
    this.filterBillingAccountsForSelectedTeam();
  }

  private filterBillingAccountsForSelectedTeam(): void {
    this.displayedBillingAccounts = this.billingAccounts
      .filter((account) => account.teamId == this.selectedTeam.id);

    this.orderDisplayedBillingAccounts();
  }

  private orderDisplayedBillingAccounts(): void {
    this.displayedBillingAccounts.sort(this.orderByCreditsCount)
  }

  private orderByCreditsCount(firstAccount: BillingAccountWithMetadata, secondAccount: BillingAccountWithMetadata): number {
    return secondAccount.creditsCount - firstAccount.creditsCount;
  }
}
