<div class="row">
  <div class="col-12 col-md-6">
    <span class="copyright">Copyright <a href="https://www.connectourkids.org">Connect Our Kids</a> {{year}}</span>
  </div>
  <div class="col-12 col-md-6 linksColumn">
    <a (click)="termsClick()">Terms&nbsp;and&nbsp;Conditions</a>
    <a (click)="privacyClick()">Privacy&nbsp;Policy</a>
    <a href="mailto:support@connectourkids.org?subject=People%20Search%20Help">Contact&nbsp;Support</a>
  </div>
</div>

