import { Component } from '@angular/core';
import { BillingAccount } from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  templateUrl: './no-credits-in-billing-account-modal.component.html',
  styleUrls: ['./no-credits-in-billing-account-modal.component.scss'],
})
export class NoCreditsInBillingAccountModal {

  public billingAccount: BillingAccount;

  constructor(
    private modalRef: NgbActiveModal,
  ) {}

  public close() {
    this.modalRef.close();
  }
}
