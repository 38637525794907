<div class="container">
  <app-header></app-header>

  <div class="row errorMessageRow">
    <div class="col-12">
      <div class="alert alert-warning">There was a problem authenticating your account. The error message was "{{auth.lastErrorMessage}}". Please try again later, or <a href="mailto:support@connectourkids.org">Contact Support</a>.</div>
    </div>
  </div>

    <app-footer></app-footer>

</div>
