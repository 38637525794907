import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';

enum VettedUserOrganizationRole {
  WWK_RECRUITER = 'WWK_RECRUITER',
  WWK_SUPERVISOR = 'WWK_SUPERVISOR',
  CASA = 'CASA',
  CASEWORKER = 'CASEWORKER',
  OTHER = ''
}

interface KycAnswers {
  id: number | null;
  workTitle: string | null;
  agency: string | null;
  contactPersonName: string | null;
  contactPersonContactForm: string | null;
  plan: string | null;
  proofOfWorkImage: string | null;
  submitted: boolean | null;
  changesRequested: boolean | null;
  createdAt: any | null;
  updatedAt: any | null;
}

@Component({
  selector: 'app-kyc-not-vetted',
  templateUrl: './kyc-not-vetted.component.html',
  styleUrls: ['./kyc-not-vetted.component.scss']
})
export class KycNotVettedComponent implements OnInit {

  public readonly KYC_FORM_URL = environment.FC_API_URL + '?openKycDialog=true';
  public readonly VettedUserOrganizationRole = VettedUserOrganizationRole;
  public readonly VETTED_USER_ORGANIZATION_ROLE_OPTIONS = [
    {
      name: 'WWK recruiter',
      value: VettedUserOrganizationRole.WWK_RECRUITER,
    },
    {
      name: 'WWK supervisor',
      value: VettedUserOrganizationRole.WWK_SUPERVISOR,
    },
    {
      name: 'Casa',
      value: VettedUserOrganizationRole.CASA,
    },
    {
      name: 'Caseworker',
      value: VettedUserOrganizationRole.CASEWORKER,
    },
    {
      name: 'Other',
      value: VettedUserOrganizationRole.OTHER,
    },
  ];

  private readonly kycAnswersEndpointUrl = environment.API_URL + '/api/kyc-answers';

  public answers: KycAnswers;
  public form: UntypedFormGroup;

  public selectedRoleOption = VettedUserOrganizationRole.WWK_RECRUITER;
  public otherRole: string;

  public isVerifyingByPicture = false;

  public roleProofImage = '/assets/logo.png';
  public roleProofBase64ImageWithoutMetadata: string;
  private filename: string;
  private mimetype: string;

  public loaded = false;
  public saving = false;

  public constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder
  ) {
  }

  public ngOnInit(): void {
    this.loadSavedAnswers();
  }

  public saveKycAnswers(): void {
    const requestBody = {
      ...this.form.value,
      proofOfWorkImage: {
        filename: this.filename,
        mimetype: this.mimetype,
        content: this.roleProofBase64ImageWithoutMetadata
      }
    };

    this.saving = true;
    this.httpClient.post(this.kycAnswersEndpointUrl, requestBody, {
      headers: {
        'authorization': 'Bearer ' + this.authService.accessToken
      }
    }).subscribe(
      () => {
        this.form.disable();
        this.saving = false;
      },
      () => {
        this.saving = false;
      });
  }

  public onRoleProofImageSelect(fileInput: any): void {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const image = new Image();
      image.src = e.target.result;
      image.onload = (rs) => {
        const imgBase64Path = e.target.result;
        this.roleProofImage = imgBase64Path;

        const base64WithoutMetadata = imgBase64Path.split(',')[1];
        this.roleProofBase64ImageWithoutMetadata = base64WithoutMetadata;
      };
    };

    const file = fileInput.target.files[0];
    this.filename = file.name;
    this.mimetype = file.type;
    reader.readAsDataURL(file);
  }

  private loadSavedAnswers(): void {
    this.authService.waitForAuthReady()
      .then((authenticated) => {
        if (!authenticated) {
          return;
        }

        this.loaded = false;
        this.httpClient.get(this.kycAnswersEndpointUrl, {
          headers: {
            'authorization': 'Bearer ' + this.authService.accessToken
          }
        }).subscribe((response) => {
        this.answers = Object.assign({} as KycAnswers, response);
        this.loaded = true;

        this.form = this.formBuilder.group({
          workTitle: [this.answers.workTitle],
          agency: [this.answers.agency],
          contactPersonName: [this.answers.contactPersonName],
          contactPersonContactForm: [this.answers.contactPersonContactForm],
          plan: [this.answers.plan],
          proofOfWorkImage: null
        });

        this.updateSelectedRoleValue();
        if (this.answers.submitted) {
          this.form.disable();
        }
      });
    });
  }

  private updateSelectedRoleValue(): void {
    if (!this.answers.workTitle) {
      return;
    }

    this.selectedRoleOption = VettedUserOrganizationRole[this.answers.workTitle];
    if (!this.selectedRoleOption) {
      this.selectedRoleOption = VettedUserOrganizationRole.OTHER;
      this.otherRole = this.answers.workTitle;
    }
  }
}
