import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { environment } from "../../environments/environment";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AnalyticsService } from "../analytics.service";
import { BillingService } from '../billing.service';
import { BillingAccount } from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  private readonly ON_DESTROY = new Subject();

  @ViewChild('videoModal', { static: true })
  public videoModal: ElementRef;

  public selectedBillingAccount: BillingAccount;

  constructor(
    private auth: AuthService,
    private title: Title,
    private modal: NgbModal,
    private analytics: AnalyticsService,
    private billingService: BillingService,
  ) { }

  public ngOnInit(): void {
    this.title.setTitle(environment.APP_NAME);
    this.loadSelectedBillingAccount();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();    
    this.ON_DESTROY.complete();    
  }

  public openIntroductionVideo(): void {
    this.analytics.sendEvent("open", "introduction-video");

    this.modal.open(this.videoModal, {
      size: 'lg',
      centered: true,
      beforeDismiss: () => {
        this.analytics.sendEvent("close", "introduction-video");
        return true;
      }
    });
  }

  private loadSelectedBillingAccount(): void {
    this.billingService.getSelectedBillingAccount()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe({
        next: (account) => this.selectedBillingAccount = account
      });
  }

  isAuthenticated() {
    return this.auth.isAuthenticated();
  }
}
