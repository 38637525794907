import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { TeamUserService, TeamWithMetadata } from '../services/team-user.service';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Roles, User } from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { AuthService } from '../auth.service';
import { Subject, merge } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { TeamService } from '../team.service';

@Component({
	selector: 'app-team',
	templateUrl: './team.component.html',
	styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit, OnDestroy {

	public readonly ON_DESTROY = new Subject<void>();
	public readonly ON_TEAM_CHANGE = new Subject<void>();

	@ViewChild('userSettingsModal', { static: true })
	public userSettingsModal: ElementRef;

	@ViewChild('deleteUserConfirmationModal', { static: true })
	public deleteUserConfirmationModal: ElementRef;

	public loading = false;
	public loadingUserAction = false;

	public teamUsers: User[] = [];

	public userGroup = new UntypedFormGroup({
		firstName: new UntypedFormControl('', [Validators.required]),
		lastName: new UntypedFormControl('', [Validators.required]),
		emailAdress: new UntypedFormControl('', [Validators.required, Validators.email, this.emailAddressAlreadyInUseControl()]),
	});

	public currentUser: User;
	public userToBeDeleted: User;

	private currentOpenedModal: NgbModalRef;

	public get canSave(): boolean {
		return this.userGroup.valid || this.userGroup.pristine;
	}

	constructor(
		private modal: NgbModal,
		private authService: AuthService,
		private teamService: TeamService,
		private teamUserService: TeamUserService,
	) {
	}

	public ngOnInit(): void {
		this.loading = true;
		this.authService.getUser()
			.pipe(first())
			.subscribe((user) => {
				this.currentUser = user;
				this.teamService.getSelectedTeam()
					.pipe(takeUntil(this.ON_DESTROY))
					.subscribe((team) => this.selectTeam(team));
			});
	}

	public ngOnDestroy(): void {
		this.ON_DESTROY.next();
		this.ON_DESTROY.complete();
	}

	public save(): void {
		if (!this.userGroup.valid) {
			this.userGroup.markAllAsTouched();
			this.userGroup.markAsDirty();
			return;
		}

		this.loadingUserAction = true;

		this.teamUserService.createUser({
			firstName: this.userGroup.controls.firstName.value,
			lastName: this.userGroup.controls.lastName.value,
			emailAddress: this.userGroup.controls.emailAdress.value,
			role: Roles.MEMBER
		}).subscribe({
			next: () => {
				this.currentOpenedModal.close();
				this.loadingUserAction = false;
			},
			error: () => this.loadingUserAction = false
		});
	};

	public openDeleteUserConfirmationModal(user: User) {
		this.userToBeDeleted = user;
		this.currentOpenedModal = this.modal.open(this.deleteUserConfirmationModal, {
			size: 'md',
			centered: true,
			beforeDismiss: () => this.resetForm(),
		});

		this.currentOpenedModal.result.then(result => {
			if (result) {
				this.loadingUserAction = true;
				this.teamUserService.removeUser(this.userToBeDeleted.id)
				.pipe(first())
				.subscribe({
					next: () => this.loadingUserAction = false,
					error: () => this.loadingUserAction = false
				});
			}

			this.userToBeDeleted = null;
		});
	};

	public openUserSettingsModal() {
		this.currentOpenedModal = this.modal.open(this.userSettingsModal, {
			size: 'md',
			centered: true,
			beforeDismiss: () => this.resetForm()
		});
	};

	public close(result: boolean) {
		this.resetForm();
		this.currentOpenedModal.close(result);
	}

	private selectTeam(team: TeamWithMetadata): void {
		const componentDestructionOrTeamChange = merge(this.ON_DESTROY, this.ON_TEAM_CHANGE);

		this.loading = true;
		this.teamUserService.getUsersOfTeam(team.id)
			.pipe(takeUntil(componentDestructionOrTeamChange))
			.subscribe((users) => {
				this.teamUsers = [this.currentUser, ...users.filter(teamUser => teamUser.id !== this.currentUser.id)];
				this.loading = false;
			});
	}

	private resetForm(): boolean {
		this.userGroup.clearAsyncValidators();
		this.userGroup.reset({
			firstName: '',
			lastName: '',
			emailAdress: ''
		});
		this.userGroup.markAsPristine();
		return true;
	}

	private emailAddressAlreadyInUseControl(): ValidatorFn {
		return (control: UntypedFormControl): ValidationErrors | null => {
			const emailAddress = control.value;
			const emailAlreadyInUse = this.teamUsers.some((user) => emailAddress === user.email);
			if (!emailAlreadyInUse) {
				return null;
			}

			return { emailAlreadyInUse: true };
		}
	}
}
