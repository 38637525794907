<div class="loadingComplete"
  *ngIf="loadingComplete">
  <router-outlet></router-outlet>
</div>


<div class="loadingNotComplete text-center"
  *ngIf="!loadingComplete">
  <img src="/assets/loading.gif" />
</div>
