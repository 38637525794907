import { Component, OnDestroy, OnInit } from '@angular/core';
import { TeamWithMetadata } from '../services/team-user.service';
import { Subject, combineLatest, merge } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { BillingAccountWithMetadata, BillingCreditBundleWithMetadata, BillingService } from '../billing.service';
import { CreditType } from '@connect-our-kids/connect-our-kids-lib';
import { BillingBundleStatus } from '@connect-our-kids/connect-our-kids-lib/generated/graphql';
import { TeamService } from '../team.service';

const BUNDLE_STATUS_PRIO = {
  ACTIVE: 4,
  NEW: 3,
  EMPTY: 2,
  EXPIRED: 1
};

@Component({
  selector: 'bundles',
  templateUrl: './bundles.component.html',
  styleUrls: ['./bundles.component.scss']
})
export class BundlesComponent implements OnInit, OnDestroy {

  private readonly ON_DESTROY = new Subject<void>();
  private readonly ON_BILLING_BUNDLES_CHANGE = new Subject<void>();

  public loading = false;
  public activatingBundle = false;

  public selectedBillingAccount: BillingAccountWithMetadata;

  public displayedBillingAccounts: BillingAccountWithMetadata[] = [];
  public displayedBundles: BillingCreditBundleWithMetadata[] = [];

  private billingAccounts: BillingAccountWithMetadata[] = [];
  private bundles: BillingCreditBundleWithMetadata[] = [];

  constructor(
    private teamService: TeamService,
    private billingService: BillingService,
  ) {
  }

  public ngOnInit(): void {
    this.getUserBillingAccountsAndBundles();
  }

  public ngOnDestroy(): void {
    this.ON_DESTROY.next();
    this.ON_DESTROY.complete();
    this.ON_BILLING_BUNDLES_CHANGE.complete();
  }

  public selectBillingAccount(billingAccount: BillingAccountWithMetadata): void {
    this.selectedBillingAccount = billingAccount;
    this.filterBundlesForSelectedBillingAccount();
  }

  public canBundleBeActivated(bundle: BillingCreditBundleWithMetadata): boolean {
    if (this.loading || this.activatingBundle) {
      return false;
    }

    return BillingBundleStatus.NEW === bundle.status;
  }

  public activateBundle(bundle: BillingCreditBundleWithMetadata): void {
    const activateBundleObservable = bundle.belongsToPersonalAccount
      ? this.billingService.activateUserBillingCreditsBundle(bundle.id)
      : this.billingService.activateTeamBillingCreditsBundle(bundle.teamId, bundle.id);
    
    this.activatingBundle = true;
    activateBundleObservable.subscribe({
      next: () => this.activatingBundle = false,
      error: () => this.activatingBundle = false,
    });
  }

  private getUserBillingAccountsAndBundles(): void {
    this.loading = true;

    combineLatest([
      this.billingService.getCurrentBillingAccounts(),
      this.billingService.getCurrentSelectedBilingAccount()
    ])
    .subscribe(([billingAccounts, selectedAccount]) => {
      this.billingAccounts = billingAccounts;
      this.selectedBillingAccount = selectedAccount;

      this.loadBillingAccountsAndBundles();
    });
  }

  private loadBillingAccountsAndBundles(): void {
    this.billingService.getBillingCreditsBundles()
      .pipe(takeUntil(this.ON_DESTROY))
      .subscribe((bundles) => {
        this.bundles = bundles;

        this.ON_BILLING_BUNDLES_CHANGE.next();
        
        this.filterBundlesForSelectedBillingAccount();
        this.filterBillingAccountsAndBundlesOnTeamChange();
      });
  }

  private filterBillingAccountsAndBundlesOnTeamChange(): void {
    const componentDestructionOrBillingAccountsChange = merge(this.ON_DESTROY, this.ON_BILLING_BUNDLES_CHANGE);
    
    this.teamService.getSelectedTeam()
      .pipe(takeUntil(componentDestructionOrBillingAccountsChange))
      .subscribe((team) => this.selectTeam(team));
  }

  private selectTeam(team: TeamWithMetadata): void {
    const billingAccountFilteringFunction = team.isPersonalTeam
      ? (billingAccount: BillingAccountWithMetadata) => CreditType.PERSONAL === billingAccount.type
      : (billingAccount: BillingAccountWithMetadata) => billingAccount.teamId === team.id

    this.displayedBillingAccounts = this.billingAccounts.filter(billingAccountFilteringFunction);

    if (this.selectedBillingAccount.teamId != team.id) {
      const defaultOrFirstBillingAccountInTeam = this.displayedBillingAccounts.find((billingAccount) => billingAccount.isDefault) || this.displayedBillingAccounts[0];
      this.selectBillingAccount(defaultOrFirstBillingAccountInTeam);
    }

    this.loading = false;
  }

  private filterBundlesForSelectedBillingAccount(): void {
    if (!this.selectedBillingAccount) {
      this.displayedBundles = [];
      return;
    }

    this.displayedBundles = this.bundles.filter((bundle) => {
      if (CreditType.PERSONAL === this.selectedBillingAccount.type) {
        return bundle.billingAccountId == null;
      }

      return bundle.billingAccountId === this.selectedBillingAccount.id;
    });

    this.displayedBundles.sort(this.sortBundlesByStatusAndExpirationDate);
  }

  private sortBundlesByStatusAndExpirationDate(bundle1: BillingCreditBundleWithMetadata, bundle2: BillingCreditBundleWithMetadata): number {
    const hasEqualStatus = BUNDLE_STATUS_PRIO[bundle1.status!] === BUNDLE_STATUS_PRIO[bundle2.status!];
    if (!hasEqualStatus) {
      const hasHigherStatus = BUNDLE_STATUS_PRIO[bundle1.status!] > BUNDLE_STATUS_PRIO[bundle2.status!];
      return hasHigherStatus ? -1 : 1;
    }

    return bundle1.expirationDate.getTime() - bundle2.expirationDate.getTime();
  }
}
