export const environment = {
  production: true,
  API_URL: "https://search.connectourkids.org",
  FC_API_URL: "https://family.connectourkids.org",
  APP_URL: "https://search.connectourkids.org",
  AUTH0_CLIENT_ID: "i5NGofkl7Z4jEexA7YTbasrLT8pcKLDU",
  AUTH0_AUDIENCE: "https://api.connectourkids.org/",
  APP_NAME: "People Search by Connect Our Kids",
  SENTRY_ENABLED: true,
  ENVIRONMENT_NAME: "prod"
};
