<div class="container">
  <app-header [disabledLogoNavigation]="true"></app-header>

  <ng-container *ngIf="loaded else loadingTemplate">
    <form [formGroup]="form" (submit)="saveKycAnswers()">
      <div class="form-group row">
        <label for="role" class="col-4 col-form-label text-right">Select your role</label>
        <div class="col-8">
          <select class="form-control"
              id="role" 
              [disabled]="answers.submitted"
              [ngModelOptions]="{ standalone: true }"
              [(ngModel)]="selectedRoleOption">
            <option *ngFor="let role of VETTED_USER_ORGANIZATION_ROLE_OPTIONS" value="{{ role.value }}">{{ role.name }}</option>
          </select>
        </div>
      </div>
      <div class="form-group row" *ngIf="selectedRoleOption === VettedUserOrganizationRole.OTHER">
        <label for="agencyName" class="col-4 col-form-label text-right">Briefly describe your role or title</label>
        <div class="col-8">
          <input type="text"
            class="form-control"
            id="agencyName"
            placeholder="Other role"
            [disabled]="answers.submitted"
            [(ngModel)]="otherRole"
            [ngModelOptions]="{ standalone: true }">
        </div>
      </div>
      <div class="form-group row">
        <label for="agencyName" class="col-4 col-form-label text-right">Name of your agency</label>
        <div class="col-8">
          <input type="text" class="form-control" id="agencyName" formControlName="agency" placeholder="Agency">
        </div>
      </div>
      <div class="form-group row">
        <label for="usage" class="col-4 col-form-label text-right">How will you use our tool</label>
        <div class="col-8">
          <textarea class="form-control" id="usage" formControlName="plan" rows="3" placeholder="Write.."></textarea>
        </div>
      </div>
      <fieldset class="form-group">
        <div class="row">
          <legend class="col-form-label col-4 pt-0 text-right">Select one of the following to confirm your role</legend>
          <div class="col-8">
            <div class="form-check pl-0">
              <input
                  type="radio"
                  name="verification-method"
                  id="verification-by-supervisor"
                  [disabled]="answers.submitted"
                  [(ngModel)]="isVerifyingByPicture"
                  [ngModelOptions]="{ standalone: true }"
                  [value]="false">
              <label for="verification-by-supervisor">Provide the name and contact information of your supervisor</label><br>
              <input
                  type="radio"
                  name="verification-method"
                  id="verification-by-picture"
                  [disabled]="answers.submitted"
                  [(ngModel)]="isVerifyingByPicture"
                  [ngModelOptions]="{ standalone: true }"
                  [value]="true">
              <label for="verification-by-picture">Upload proof of role</label><br>
            </div>
          </div>
        </div>
      </fieldset>
      <ng-container *ngIf="!isVerifyingByPicture">
        <div class="form-group row">
          <label for="name" class="col-4 col-form-label text-right">Name</label>
          <div class="col-8">
            <input type="text" class="form-control" id="name" formControlName="contactPersonName" placeholder="Name">
          </div>
        </div>
        <div class="form-group row">
          <label for="contact-information" class="col-4 col-form-label text-right">Contact information</label>
          <div class="col-8">
            <input class="form-control" id="contact-information" formControlName="contactPersonContactForm"
              placeholder="Contact information">
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isVerifyingByPicture">
        <div class="form-group row">
          <label for="role-proof" class="col-4 col-form-label text-right">Role proof</label>
          <div class="col-8">
            <input type="file" id="role-proof" (change)="onRoleProofImageSelect($event)">
          </div>
        </div>
      </ng-container>
      <div class="form-group row">
        <div class="col-4"></div>
        <div class="col-8">
          <button [disabled]="saving || answers?.submitted" type="submit" class="btn btn-primary pl-4 pr-4">Save</button>
        </div>
      </div>
    </form>
  </ng-container>

  <ng-template #loadingTemplate>
    Loading...
  </ng-template>
</div>
