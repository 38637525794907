import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";
import { TeamWithMetadata } from "./services/team-user.service";

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  private readonly selectedTeam = new ReplaySubject<TeamWithMetadata>(1);

  public setSelectedTeam(team: TeamWithMetadata) {
    this.selectedTeam.next(team);
  }

  public getSelectedTeam(): Observable<TeamWithMetadata> {
    return this.selectedTeam;
  }
}
