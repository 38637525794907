<div class="container-fluid p-0">

  <app-header #header></app-header>

  <div class="container mt-5">
    <app-search-form #searchForm (searchUpdated)="searchFormUpdated()"></app-search-form>

    <div class="row" *ngIf="viewState == ViewState.QUERY_NOT_VALID">
      <div class="col-12 ">
        <div class="alert alert-warning">{{searchValidationResult.errorMessage}}</div>
      </div>
    </div>
  
    <div class="row" *ngIf="viewState == ViewState.SEARCH_RESULT && searchResult.warnings != null">
      <div class="col-12 ">
        <div class="alert alert-warning" *ngFor="let warning of searchResult.warnings">
          {{translateWarningMessage(warning)}}</div>
      </div>
    </div>
  
    <div class="row" *ngIf="viewState == ViewState.SEARCH_LOADING">
      <div class="col-12 text-center">
        <img src="/assets/loading.gif" />
      </div>
  
    </div>
  
    <div class="row" *ngIf="viewState == ViewState.SEARCH_ERROR">
      <div class="col-12">
        <div class="alert alert-warning">There was an error during the search. Please try again later, or <a
            href="mailto:support@connectourkids.org">Contact Support</a>.</div>
      </div>
  
    </div>
  
    <div class="row"
      *ngIf="viewState == ViewState.KYC_NOT_VETTED">
      <div class="col-12">
        <div class="alert alert-warning">You haven't been KYC vetted yet to be able to use this tool.</div>
      </div>
    </div>
  
    <div class="row"
      *ngIf="viewState == ViewState.NO_RESULTS">
      <div class="col-12">
        Sorry, no results were found for your search. Check the spelling and try again.
      </div>
  
    </div>
  
    <div class="row" *ngIf="!authenticated() && viewState == ViewState.SEARCH_RESULT">
      <div class="col-12">
        <div class="alert alert-info">This is a preview. 
          <a href="javascript:void(0)" (click)="header.openSocialWorkerCheckModal()">Click here</a> to find out more.</div>
      </div>
    </div>
  
    <div class="row" *ngIf="viewState == ViewState.SEARCH_RESULT && searchResult?.person != null">
      <div class="col-12 personResultWrapper">
  
        <!-- Personal Details -->
        <div class="row">
          <div class="col-sm-3">
            <img src="{{generateThumbnailUrl(searchResult.person)}}" width="100" height="100" />
          </div>
          <div class="col-sm-9">
            <span class="personName" *ngIf="searchResult.person.names != null && searchResult.person.names.length > 0">
              {{searchResult.person.names[0]?.first}}
              {{searchResult.person.names[0]?.middle}}
              {{searchResult.person.names[0]?.last}}</span>
            <div>
              {{searchResult.person.dob?.display}} {{searchResult.person.gender?.content}}
  
            </div>
          </div>
  
        </div>
  
        <!-- Email -->
        <div class="row" *ngIf="filterEmails(searchResult.person?.emails)?.length  > 0">
          <div class="col-sm-3 sectionLabel">Emails</div>
          <div class="col-sm-9 ">
            <div class="dataRow" *ngFor="let email of filterEmails(searchResult.person?.emails); let in = index">
              <a href="javascript:void(0)" (click)="emailClick(email)">{{email.address}}</a>
              <div class="metaData">
                <span *ngIf="email['@type'] != null">{{email["@type"]}}</span>
                <span *ngIf="getLastSortDate(email) != null
                  && getLastSortDate(email).getFullYear() != 1899">{{getLastSortDate(email).getFullYear()}}</span>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Phone Numbers -->
        <div class="row" *ngIf="filterPhones(searchResult.person?.phones)?.length  > 0">
          <div class="col-sm-3 sectionLabel">Phone Numbers</div>
          <div class="col-sm-9">
            <div class="dataRow" *ngFor="let phone of filterPhones(searchResult.person?.phones); let in = index">
              <a href="javascript:void(0)}" (click)="phoneClick(phone)">{{generatePhoneDisplay(phone)}}</a>
              <div class="metaData">
                <span *ngIf="phone['@type'] != null">{{phone["@type"]}}</span>
                <span *ngIf="getLastSortDate(phone) != null
                      && getLastSortDate(phone).getFullYear() != 1899">{{getLastSortDate(phone).getFullYear()}}</span>
              </div>
            </div>
          </div>
        </div>
  
  
        <!-- Addresses -->
        <div class="row" *ngIf="filterAddresses(searchResult.person?.addresses)?.length  > 0">
          <div class="col-sm-3 sectionLabel">Addresses</div>
          <div class="col-sm-9">
            <div class="dataRow" *ngFor="let address of filterAddresses(searchResult.person?.addresses); let in = index">
              <a href="javascript:void(0)" (click)="addressClick(address)">
                <div *ngIf="address.street != null">{{generateAddressHomeStreetDisplay(address)}}</div>
                <div>{{address.city}}<span *ngIf="address.city != null">,</span> {{address.state}}
                  {{generateAddressZipcodeDisplay(address)}}</div>
  
              </a>
              <div class="metaData">
                <span *ngIf="address['@type'] != null">{{address["@type"]}}</span>
                <span
                  *ngIf="getLastSortDate(address) != null
                        && getLastSortDate(address).getFullYear() != 1899">{{getLastSortDate(address).getFullYear()}}</span>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Profiles
        <div class="row">
          <div class="col-sm-3">Profiles</div>
          <div class="col-sm-9">
            <div *ngFor="let profile of searchResult.person?.user_ids; let in = index">
              <a href="{{generateProfileUrl(profile)}}">{{generateProfileName(profile)}}</a>
            </div>
          </div>
        </div>
        -->
  
        <!-- URLS -->
        <div class="row" *ngIf="filterUrls(searchResult.person?.urls).length  > 0">
          <div class="col-sm-3 sectionLabel">Websites</div>
          <div class="col-sm-9">
            <div class="dataRow" *ngFor="let url of filterUrls(searchResult.person?.urls); let in = index">
              <a href="javascript:void(0)" (click)="urlClick(url)">{{url["@name"]}}</a>
              <div class="metaData">
                <span *ngIf="url['@type'] != null">{{url["@type"]}}</span>
  
                <span *ngIf="getLastSortDate(url) != null
                  && getLastSortDate(url).getFullYear() != 1899">{{getLastSortDate(url).getFullYear()}}</span>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Relatives -->
        <div class="row" *ngIf="filterRelationships(searchResult.person?.relationships).length  > 0">
          <div class="col-sm-3 sectionLabel">Relationships</div>
          <div class="col-sm-9">
            <div class="dataRow"
              *ngFor="let relationship of filterRelationships(searchResult.person?.relationships); let in = index">
              <a href="javascript:void(0)"
                (click)="relationshipClick(relationship)">{{generateRelationshipDisplay(relationship)}}</a>
              <div class="metaData">
                <span *ngIf="authenticated() && relationship['@type'] != null">{{relationship["@type"]}}</span>
              </div>
            </div>
          </div>
        </div>
  
      </div>
    </div>
  
    <div class="row" *ngIf="viewState == ViewState.SEARCH_RESULT && searchResult?.possible_persons != null">
  
      <div class="col-12">
        <div class="row">
          <div class="col-3 col-sm-2">
  
          </div>
          <div class="col-9 col-sm-10">
            <h3>Possible Matches</h3>
          </div>
        </div>
  
        <div class="row possibleMatchRow" *ngFor="let person of searchResult.possible_persons"
          (click)="personClick($event, person)">
          <div class="col-3 col-sm-2">
            <a href="javascript:void(0)">
              <img src="{{generateThumbnailUrl(person)}}" width="75" height="75" /></a>
          </div>
          <div class="col-9 col-sm-10">
            <a href="javascript:void(0)" (click)="personClick($event, person)"><span
                *ngIf="person.names != null"><strong>{{person.names[0]?.display}}</strong></span></a><br />
            {{person.dob?.display}}<span *ngIf="person.dob != null && person.gender != null">,</span>
            {{person.gender?.content}}<br />
            <span class="possibleMatchCityState" *ngFor="let cityState of uniqueCityStates(person.addresses)">
              {{cityState}}
            </span>
  
          </div>
        </div>
      </div>
    </div>
  
    <app-footer></app-footer>
  </div>

</div>

<ng-template #urlActionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">View or Search?</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Would you like to view this URL, or perform a search on it?</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c(ModalResult.ACTION)">View the URL</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="c(ModalResult.SEARCH)">Perform a
      search</button>
  </div>
</ng-template>

<ng-template #addressActionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">View or Search?</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Would you like to view this address on a map, or perform a search on it?</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c(ModalResult.ACTION)">View on map</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="c(ModalResult.SEARCH)">Perform a
      search</button>
  </div>
</ng-template>

<ng-template #emailActionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Email Options</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p class="desc-txt">Perform a search using the email address</p>
    <button type="button" class="btn btn-primary d-flex mb-3" data-dismiss="modal"
      (click)="c(ModalResult.SEARCH)">Search email address</button>
    <p class="desc-txt">Send new email with Outlook or other email application</p>
    <button type="button" class="btn btn-primary d-flex mb-2" ngbAutofocus (click)="c(ModalResult.ACTION)">Send new
      email</button>
    <p class="small-txt">Your email application will automatically open if you have set it up. Click <a
        href="https://help.connectourkids.org/en/articles/6506187-how-do-i-set-up-my-email-application">here</a> for
      instructions on how to set it up for Windows and Apple.</p>
    <p class="desc-txt">Copy the email address to your clipboard</p>
    <button type="button" (click)="copySelectedEmailAddressToClipboard()" class="btn btn-primary d-flex mb-2">Copy email
      to clipboard</button>
  </div>
</ng-template>

<ng-template #phoneActionModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Call or Search?</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>Would you like to call this number, or perform a search? Calling requires a device capable of dialing phone
      numbers.</p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c(ModalResult.ACTION)">Call this number</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="c(ModalResult.SEARCH)">Perform a
      search</button>
  </div>
</ng-template>

<ng-template #childSupportedModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">For our metrics</h4>
  </div>
  <div class="modal-body">
    <p>Is this search in support of a foster child?</p>
    <p><a href="javascript:void(0)" (click)="whyAmIBeingAskedClicked()">Why am I being asked this?</a> </p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c(ModalResult.YES)">Yes, I'm supporting a foster
      child</button>
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="c(ModalResult.NO)">No, just
      testing</button>
  </div>
</ng-template>

<ng-template #afterChildSupportedModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Keep us informed</h4>
  </div>
  <div class="modal-body">
    <p>Please click the <span style="color:red;"> Serving A New Child?</span> link at the top each time you use People
      Search for a child that has never previously benefited from People Search. </p>
    <p><a href="javascript:void(0)" (click)="whyAmIBeingAskedClicked()">Why am I being asked this?</a> </p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c(ModalResult.YES)">Go to search</button>
  </div>
</ng-template>

<ng-template #whyAmIBeingAskedModal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title">Metrics bring funds</h4>
  </div>
  <div class="modal-body">
    <p>We are able to offer these tools to you for free, because our funding partners appreciate the impact of your hard
      work and the
      effectiveness of these tools. One of the most important metrics is "number of children served". When you indicate
      that you are serving a child,
      Connect Our Kids includes that in our aggregate reporting.</p>
    <p>We do not share anything other than the total number of children served by People Search. We do not correlate
      those counts to you or organization. We do not collect
      or share any case related information.</p>
    <p>If you have any concerns, <a href="mailto:hello@connectourkids.org">Contact Us</a></p>
  </div>
  <div class="modal-footer justify-content-between">
    <button type="button" class="btn btn-primary" ngbAutofocus (click)="c(ModalResult.YES)">Go back</button>
  </div>
</ng-template>
